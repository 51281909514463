import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class AlmacenamientoLocalService {

  constructor() { }

  

  HaySesion() {
   
      if(this.get("DatosCliente") == null)
        return  false;
      else  
        return true;  
  }

  set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.error('Error saving', e);
    }
  }

  get(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error('Error getting data', e);
      return null;
    }
  }

  remove(key: string): void {
    try {
      localStorage.removeItem(key);
      console.log('Se elimino la  key: ' + key);
    } catch (e) {
      console.error('Error removing key', e);
    }
  }

  clear(): void {
    try {
      localStorage.clear();
    } catch (e) {
      console.error('Error cleaning localstorage', e);
    }
  }
}

