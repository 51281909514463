import { Component, OnInit, ElementRef, ViewChild ,EventEmitter,Output} from '@angular/core';
import {Product} from "../../modals/product.model";
import {CartItem} from "../../modals/cart-item";
import {ProductService} from "../shared/services/product.service";
import {CartService} from "../shared/services/cart.service";
import { Router, NavigationEnd } from '@angular/router';
import { SidebarMenuService } from '../shared/sidebar/sidebar-menu.service';
import { SidenavMenu } from '../shared/sidebar/sidebar-menu.model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass']
})
export class MainComponent implements OnInit {
  
  @Output() CambioDeTextoDeBusqueda = new EventEmitter();

  public sidenavMenuItems:Array<any>; 

  public currencies = ['MX'];
  public currency:any;
  public flags = [
    { name:'México', image: 'assets/images/flags/mx.svg' }
    
  ]
  public flag:any;

  products: Product[];  

  indexProduct: number;
  shoppingCartItems: CartItem[] = [];

  public banners = [];

  wishlistItems  :   Product[] = [];

  public url : any;
  public TextoQueSeBusca="";

  navItems: SidenavMenu[] = [];
 

  Referencia : any;
  
  ItemMenuLateral: any={}; 
  ItemMenuLateralHijo: any={}; 
  Indice: number;
  MenuHijo: SidenavMenu[] = [];
  public Categorias: any[]=[];
  public Categoria:  {};

  Marcas: any[]=[];
  Marca: {};

  allItems: any[]=[];

  constructor(public router: Router, private cartService: CartService, public sidenavMenuService:SidebarMenuService, private productService: ProductService) {
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        
      }
    } )    
  }

  ngAfterViewInit() {
   
    
  }

  ngOnInit() {
    this.currency = this.currencies[0];
    this.flag = this.flags[0];

    this.LlenaMenuLateral(true);  

    this.Categorias=[];
    this.allItems=[];
    this.productService.Categorias().subscribe(allItems => {
      this.Categorias = allItems;   
      this.LlenaMenuLateral(false,true);  
      //console.log('Categorias encontradas: ' +  JSON.stringify(this.Categorias));
       })

    this.Marcas=[]; 
    this.allItems=[];
    this.productService.Marcas().subscribe(allItems => {
      this.Marcas = allItems;     
      //console.log('Marcas encontradas: ' + JSON.stringify(this.Marcas));
      this.LlenaMenuLateral(false,false,true);
       })     
   
    
  }

  LlenaMenuLateral(inicio: boolean=false,Categoria: boolean = false,Marca: boolean = false)
  {  
    //console.log('Categorias encontradas: ' +  JSON.stringify(this.Categorias));
    if(inicio){
      this.ItemMenuLateral.displayName='Inicio';
      this.ItemMenuLateral.route='/Home';    
      this.Indice=this.navItems.push(this.ItemMenuLateral); 
    }
    
  if(Categoria)
  {
      this.ItemMenuLateral={};
      this.MenuHijo=[];    
    this.ItemMenuLateral.displayName='Categorías';       
    for (var i = 0; i < this.Categorias.length; i++)
    {
      this.ItemMenuLateralHijo={};
      this.ItemMenuLateralHijo.displayName= this.Categorias[i].DESCRIPCION;
      this.ItemMenuLateralHijo.route='/home/products/' + this.ItemMenuLateralHijo.displayName + '/left-sidebar';         
      this.MenuHijo.push(this.ItemMenuLateralHijo);
      this.ItemMenuLateral.children = this.MenuHijo; 
    }   
    this.navItems.push(this.ItemMenuLateral);
    
  }
  
  if(Marca)
  {
    this.ItemMenuLateral={};
    this.MenuHijo=[];    
    this.ItemMenuLateral.displayName='Marcas';       
    for (var i = 0; i < this.Marcas.length; i++)
    {
      this.ItemMenuLateralHijo={};
      this.ItemMenuLateralHijo.displayName= this.Marcas[i].DESCRIPCION;
      this.ItemMenuLateralHijo.route='/home/products/left-sidebar/' + this.ItemMenuLateralHijo.displayName;         
      this.MenuHijo.push(this.ItemMenuLateralHijo);
      this.ItemMenuLateral.children = this.MenuHijo; 
    }   
    this.navItems.push(this.ItemMenuLateral);
  }
}

  public changeCurrency(currency){
    this.currency = currency;
  }
  public changeLang(flag){
    this.flag = flag;
  }

  
  CambioDeTextoDeBusquedaMain(nuevoTexto) {
    
    console.log('Nombre dem Modulo:' + this.Referencia.constructor.name);
    if(this.Referencia.constructor.name == 'ProductLeftSidebarComponent')
      this.Referencia.CambioDeTextoDeBusqueda(nuevoTexto);
    else
     this.router.navigate(['/home/products/left-sidebar/' + nuevoTexto]);  
    //this.CambioDeTextoDeBusqueda.emit(nuevoTexto);
   }

   onActivate(componentReference) {
    //console.log('on Activate:' + componentReference);
    //console.log('on Activate:' + componentReference.constructor.name); 
    this.Referencia=componentReference;
    //componentReference.anyFunction();
 }
  
  
}
