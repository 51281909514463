import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { CartItem } from 'src/app/modals/cart-item';
import { ProductService } from '../../shared/services/product.service';
import { CartService } from '../../shared/services/cart.service';
import { HeaderTwoComponent } from '../../shared/header-two/header-two.component';
import { ServiciosIDEAService } from '../../../services/servicios-idea.service';



@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.sass']
})
export class HomeTwoComponent implements OnInit {

  contentLoaded = false;

  products: Product[];
  public banners = [];
  ListaDeProductos: any[]=[];  
  ListaDeProductosCarrusel2: any[]=[];  

  ProdCarrusel: any={};

  shoppingCartItems: CartItem[] = [];
  wishlistItems  :   Product[] = [];

  Almacen=1;
  TextoQueSeBusca="BAYONETA";
  ClaveBuscar="";
  Alm="1";
  UrlSW: string;
  //UrlSW="http://ideaempresas.dyndns.org:8080/";
  public featuredProducts: Array<Product>;
  public onSaleProducts: Array<Product>;
  public topRatedProducts: Array<Product>;
  public newArrivalsProducts: Array<Product>;

  public slides: any[]=[];    


  constructor(private serviciosIDEA: ServiciosIDEAService, private productService: ProductService, private cartService: CartService) { }
  
    EjecutaBusquedaProducto(){   
      
     
      this.ListaDeProductos=<any>this.serviciosIDEA.BuscaProductosAsync(this.Alm,this.UrlSW,this.TextoQueSeBusca,this.ClaveBuscar,"0");
    
  }

  async  LlenaCarusel()
  {
     //
     this.ListaDeProductos= await <any>this.serviciosIDEA.BuscaProductos(this.Alm,this.UrlSW,this.TextoQueSeBusca,this.ClaveBuscar);
     //console.log(JSON.stringify(this.ListaDeProductos));
     //console.log(JSON.stringify(this.ListaDeProductos.length));
     
    if(this.ListaDeProductos !== null) 
    {
      for (var i = 0; i < this.ListaDeProductos.length; i++)  {   
        this.ProdCarrusel={};
        if(this.ListaDeProductos[i].IMAGEN != '')
        {
          this.ProdCarrusel.title='';
          this.ProdCarrusel.title='$' + this.ListaDeProductos[i].PRECIO;
          this.ProdCarrusel.image = 'http://ideasistemas.mx/idea/img/ETH/' + this.ListaDeProductos[i].IMAGEN;
         
          this.slides.push(this.ProdCarrusel);
          //console.log('Lista en carrusel: ' + this.ProdCarrusel.image);  
        }
      }
     // console.log(JSON.stringify(this.slides));
    }  	 
  }

  ngOnInit() {

    this.UrlSW=this.serviciosIDEA.ObtenURLEmpresa();

   // this.LlenaCarusel();

    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);

    
    this.productService.getProducts()
    .subscribe(
      (product: Product[]) => {
        this.products = product;
        this.ListaDeProductosCarrusel2=product; 
        //console.log(JSON.stringify(this.ListaDeProductosCarrusel2));
      }
    )
    this.productService.getBanners()
    .subscribe(
      data => this.banners = data
    );     
   
    setTimeout(() => {
      this.contentLoaded = true;
    }, 4000);
  }



}
