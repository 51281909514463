import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { ProductService } from 'src/app/components/shared/services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.sass']
})
export class CategoriesComponent implements OnInit {
 
  public Categorias: any[];
  public Categoria:  {};

  constructor(private productService: ProductService) { }

  ngOnInit() {
    this.productService.Categorias().subscribe(Categoria => this.Categorias = Categoria);
  }

}
