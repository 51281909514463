import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { AlertController } from '@ionic/angular';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Product,Producto } from 'src/app/modals/product.model';

/**
 * Encodes multi-byte Unicode string into utf-8 multiple single-byte characters
 * (BMP / basic multilingual plane only).
 *
 * Chars in range U+0080 - U+07FF are encoded in 2 chars, U+0800 - U+FFFF in 3 chars.
 *
 * Can be achieved in JavaScript by unescape(encodeURIComponent(str)),
 * but this approach may be useful in other languages.
 *
 * @param   {string} unicodeString - Unicode string to be encoded as UTF-8.
 * @returns {string} UTF8-encoded string.
 */
 function utf8Encode(unicodeString) {
  if (typeof unicodeString != 'string') throw new TypeError('parameter ‘unicodeString’ is not a string');
  const utf8String = unicodeString.replace(
      /[\u0080-\u07ff]/g,  // U+0080 - U+07FF => 2 bytes 110yyyyy, 10zzzzzz
      function(c) {
          var cc = c.charCodeAt(0);
          return String.fromCharCode(0xc0 | cc>>6, 0x80 | cc&0x3f); }
  ).replace(
      /[\u0800-\uffff]/g,  // U+0800 - U+FFFF => 3 bytes 1110xxxx, 10yyyyyy, 10zzzzzz
      function(c) {
          var cc = c.charCodeAt(0);
          return String.fromCharCode(0xe0 | cc>>12, 0x80 | cc>>6&0x3F, 0x80 | cc&0x3f); }
  );
  return utf8String;
}

/**
* Decodes utf-8 encoded string back into multi-byte Unicode characters.
*
* Can be achieved JavaScript by decodeURIComponent(escape(str)),
* but this approach may be useful in other languages.
*
* @param   {string} utf8String - UTF-8 string to be decoded back to Unicode.
* @returns {string} Decoded Unicode string.
*/
function utf8Decode(utf8String) {
  if (typeof utf8String != 'string') throw new TypeError('parameter ‘utf8String’ is not a string');
  // note: decode 3-byte chars first as decoded 2-byte strings could appear to be 3-byte char!
  const unicodeString = utf8String.replace(
      /[\u00e0-\u00ef][\u0080-\u00bf][\u0080-\u00bf]/g,  // 3-byte chars
      function(c) {  // (note parentheses for precedence)
          var cc = ((c.charCodeAt(0)&0x0f)<<12) | ((c.charCodeAt(1)&0x3f)<<6) | ( c.charCodeAt(2)&0x3f);
          return String.fromCharCode(cc); }
  ).replace(
      /[\u00c0-\u00df][\u0080-\u00bf]/g,                 // 2-byte chars
      function(c) {  // (note parentheses for precedence)
          var cc = (c.charCodeAt(0)&0x1f)<<6 | c.charCodeAt(1)&0x3f;
          return String.fromCharCode(cc); }
  );
  return unicodeString;
}

function toUnicode(theString) {
  var unicodeString = '';
  for (var i=0; i < theString.length; i++) {
    var theUnicode = theString.charCodeAt(i).toString(16).toUpperCase();
    while (theUnicode.length < 4) {
      theUnicode = '0' + theUnicode;
    }
    theUnicode = '\\u' + theUnicode;
    unicodeString += theUnicode;
  }
  return unicodeString;
}
function eliminarDiacriticos(texto) {
  return texto.normalize('NFD').replace(/[\u0300-\u036f]/g,"");
}
/*
interface Producto {
  Cve_Art: string,
  Cantidad: number,
  Cve_Alm: number,
  Costo: number,
  Precio: number,
  Descr: string,
  Imagen: string,
  Costo_Prom: number,
  Cliente: string
}
*/


@Injectable({
  providedIn: "root"
})
export class ServiciosIDEAService {

  constructor(public http: HttpClient) {     
    
  }

 //URLIDEA = "https://ideaempresas.com.mx/eth/ServiciosIDEA.dll/";
 
 //URLIDEA = "http://cesagros.dyndns.org/idea/ServiciosIDEA.dll/";
  //URLIDEA = "http://ideaempresas.dyndns.org/eth/ServiciosIDEA.dll/";
  URLIDEA:string = 'http://ethaparicio.dyndns.org:81/';
  IdApp: string = 'ETH';

  ObtenURLEmpresas(Id: string) {    
    return this.http.get<any>('http://ideaempresas.dyndns.org/IDEARest/IDEARest.dll/IDEmpresa?ID='+ Id);        
  }  

  ObtenURLEmpresa() {    
    return "https://ideaempresas.com.mx/eth/ServiciosIDEA.dll/";        
  }  
 
  LoginUsuario(Usuario: string,Pass: string,Cliente: string,URL: string) {    
 
   // return this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=41&NumUsuario=' + Usuario +  '&Password=' + Pass );       
    return this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=40&EMAIL=' + Usuario +  '&PASS_APP=' + Pass +  '&CLIE=' + Cliente);  

  }

  CargoOpcionesDeMenu()
  {
    return this.http.get<any>('./www/assets/data/menu.json');
  }

  

  ObtenListaDeProductos(almacen: string,URL: string) {    
    //console.log(URL + 'SWAcciones?Accion=22&ALMACEN=1');
    return this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=22&ALMACEN=' + almacen);        
  }

  ObtenCatalogo(Catalogo: string) {    
    //console.log(URL + 'SWAcciones?Accion=22&ALMACEN=1');
    return this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=' + Catalogo);        
  }

  BuscaProductos(almacen: string,URL: string,Texto: string, Clave: string,Esquema: string="0",Destacado: string="0",Categoria: string="") {    
   // console.log(this.URLIDEA + 'SWAcciones?Accion=20&ALMACEN=' + almacen  +'&CODIGO=' + '&DESCR=' +  Texto);

     return this.http.get<Product[]>(this.URLIDEA + 'SWAcciones?Accion=20&ALMACEN=' + almacen  +'&CODIGO=' + Clave +  '&DESCR=' +  Texto + "&Esquema=" + Esquema + "&Destacados=" + Destacado + "&Categoria=" + Categoria + "&IdApp=" + this.IdApp);
     
  }
  ListaDeProductos: any[]=[];  
  
async BuscaProductosAsync(almacen: string,URL: string,Texto: string, Clave: string,Esquema: string="0") {
    return await this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=20&ALMACEN=' + almacen  +'&CODIGO=' + Clave +  '&DESCR=' +  Texto + "&Esquema=" + Esquema ).toPromise();
    
    //return this.ListaDeProductos;
    //console.log('No issues, I will wait until promise is resolved..');
  }

  BuscaProductosPorCodigo(URL: string,almacen: string, Clave: string) {    
    // console.log(URL + 'SWAcciones?Accion=20&ALMACEN=' + almacen  +'&CODIGO=' + '&DESCR=' +  Texto);
     return this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=24&ALMACEN=' + almacen  +'&CODIGO=' + Clave );             
   }
   

  GrabaPedido (ProductosCarrito: Producto[],URL: string) {    

       const headers = {  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8;application/json,utf-8',
                           'Accept': 'application/json,utf-8, text/plain, */*;' };                        
       //QuitaDescr();
       //console.log(eliminarDiacriticos(JSON.stringify(ProductosCarrito)));
      return  this.http.post<any>(this.URLIDEA + 'SWAccionesApp', eliminarDiacriticos(JSON.stringify(ProductosCarrito)));
        //this.postId = data.id;
        
       
     }

     
  ObtenListaPedidos(cliente: string,periodo: string,URL: string) {    
    //console.log(URL + 'SWAcciones?Accion=22&ALMACEN=1');
    return this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=27&CLIE=' + cliente + '&Periodo=' + periodo + '&FECHA_D=&FECHA_H=');        
  }

  DetallePedido(CveDoc: string,URL: string) {       
    return this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=28&CVE_DOC=' + CveDoc);        
  }

  GrabaCliente(DatosCliente: any,URL: string) {    

    
    const headers = {  'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                           'Accept': 'application/json,utf-8, text/plain, */*;'
                           };         
                          
                             


   return  this.http.post<any>(this.URLIDEA + 'SWAddClienteWEB',  eliminarDiacriticos(JSON.stringify(DatosCliente)), { headers });
    
    
  }

  ObtenCliente(cliente: string,URL: string) {    
    //console.log(URL + 'SWAcciones?Accion=22&ALMACEN=1');
    return this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=30&CLIE=' + cliente );        
  }

  ModificaCliente(DatosCliente: any,URL: string) {    

    const headers = {  'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                           'Accept': 'application/json,utf-8, text/plain, */*;'
                           };         

   
   return  this.http.post<any>(this.URLIDEA + 'SWUpdClienteWEB',  eliminarDiacriticos(JSON.stringify(DatosCliente)), { headers });
    
    
  }


  SubeFotoIDEA(URL: string,Clave: string,DatosImagen: any ) {     	
   
    
    const headers = {  'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
                        'Accept': 'application/json,utf-8, text/plain, */*;' };


   return  this.http.post<any>(this.URLIDEA + 'UpImagen/' + Clave,  DatosImagen, { headers });
    
      
    } // SubeFotoIDEA    

    AjustaInventario(Producto : any, Usuario: string,URL: string) {     
   //  $http.get(url.value+'SWAcciones?ParArt='+$datos.Cve_Art+'&Accion=17&ParAlma='+almacen+'&ParConteo='+$datos.Exist+'&ParTipoMov=1&ParExist='+$datos.Existecia+'&ParPrecio='+$datos.Precio+'&Usuario='+usuarioLogueado.value+'&Costo='+$datos.Costo+'&CostoProm='+$datos.Costo_Prom)     
      return this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=17&ParArt='+ Producto.CVE_ART + 
                                      '&ParAlma='+ Producto.CVE_ALM +
                                      '&ParConteo=' + Producto.CANT +
                                      '&ParTipoMov=1' +
                                      '&ParExist=' + Producto.EXIST + 
                                      '&ParPrecio=' + Producto.PRECIO +
                                      '&Usuario=' + Usuario +
                                      '&Costo=' + Producto.COSTO +
                                      '&CostoProm=' + Producto.COSTO_PROM);      
     // console.log(Producto);  
    }

    ListaDeAlmacenes(URL: string) {       
      return this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=21');        
    }

    ReporteDeVentas(Alm: string,FechaD: Date,FechaH: Date,URL: string) {             
      return this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=18&ALMACEN=' + Alm + '&FECHA_APLI_D=' +   FechaD.toLocaleDateString('en-MX') + '&FECHA_APLI_H=' +  FechaH.toLocaleDateString('en-MX'));        
    }

    CodigosAut(URL: string) {       
      //console.log(URL);
      return this.http.get<any>(this.URLIDEA + 'SWAcciones?Accion=19');        
    }
  
}
