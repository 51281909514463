import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import { CartItem } from 'src/app/modals/cart-item';
import { CartService } from '../services/cart.service';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/components/shared/services/product.service';



@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.sass']
})
export class HeaderTwoComponent implements OnInit {

  @Output() CambioDeTextoDeBusqueda = new EventEmitter();
  @Output() CambioDeTextoDeBusquedaMain = new EventEmitter();
 

  public currencies = ['MX'];
  public currency:any;
  public flags = [
    { name:'México', image: 'assets/images/flags/mx.svg' }
    
  ]
  public flag:any;

  products: Product[];
  producto: Product;
  indexProduct: number;
  public sidenavMenuItems:Array<any>;
  shoppingCartItems: CartItem[] = [];
  Texto: string = "";

  constructor(private cartService: CartService,public router: Router,private productService: ProductService) {
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
   }

  ngOnInit() {
    this.currency = this.currencies[0];
    this.flag = this.flags[0];
    console.log('entro al header');
    
    this.Texto=this.productService.TextoBuscado;
  }
  public changeCurrency(currency){
    this.currency = currency;
  }
  public changeLang(flag){
    this.flag = flag;
  } 

  
  EjecutaBusqueda(texto: string) {
  //  console.log('Texto enviado desde Header:' + this.Texto);    
    this.CambioDeTextoDeBusquedaMain.emit(texto);
  }
}
