import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';



@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.sass']
})
export class BrandsComponent implements OnInit {

  // brands: string[] = ['all', 'Lenovo', 'Dell', 'Dell', 'Lg', 'Samsung'];
 // brands: string[] = ['all', 'Brand-1', 'Brand-2', 'Brand-3', 'Brand-4', 'Brand-5'];
  public Marcas: any[];
  public Marca:  {};
  public Todas = 'Todas';

  @Output() brandChanged = new EventEmitter();
  constructor(private productService: ProductService) { }

  ngOnInit() {
    this.productService.Marcas().subscribe(Marca => this.Marcas = Marca); 
  }


  brendSelect(event) {
    console.log(event);
  this.brandChanged.emit(
    event.value
    
  );
  }

}
