import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Product, Producto } from 'src/app/modals/product.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { ServiciosIDEAService } from '../../../services/servicios-idea.service';



// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("compareItem")) || [];

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public currency : string = 'USD';
  public catalogMode : boolean = false;

  private _url: string = "assets/data/";
  public url = "assets/data/banners.json";
  public TextoBuscado="";
  
  public compareProducts : BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public observer   :  Subscriber<{}>;

  constructor(private httpClient: HttpClient, public snackBar: MatSnackBar,private serviciosIDEA: ServiciosIDEAService) {
   this.compareProducts.subscribe(products => products = products)

   console.log("Producto.service: " +  this.TextoBuscado);
  }

  public ObtenerProductosDestacados(): Observable<Product[]> {
    return this.serviciosIDEA.BuscaProductos("1","","","","1","1");//Destacados
  }

  private products(Categoria: string="",Destacado: string="0",TextoDeBusqueda: string="",ClaveProd: string=""): Observable<Product[]> {   

    if(Destacado == "1")
      return this.ObtenerProductosDestacados();//Destacados
    else      
      return this.serviciosIDEA.BuscaProductos("1","",TextoDeBusqueda,ClaveProd,"1","",Categoria);//Destacados
        
  }

  public BusquedaDeProductos(Texto: string): Observable<Product[]> {   
    
    return this.serviciosIDEA.BuscaProductos("1","",Texto,"","1");
    
  }


  public Categorias(): Observable<any[]> {   
    
    return this.serviciosIDEA.ObtenCatalogo("60");   
    
  }

  public Marcas(): Observable<any[]> {   
    
    return this.serviciosIDEA.ObtenCatalogo("61");   
    
  }

  public GrabaPedido(Carrito: Producto[]): Observable<any[]> {   
    
    return this.serviciosIDEA.GrabaPedido(Carrito,"")
  }    

  public banners(): Observable<any[]>{
    return this.httpClient.get<any[]>(this.url);
  }


    // Get Banners
    public getBanners() {
      return this.banners();
    }

    // Get Banners
    public getProducts(): Observable<Product[]> {
      return this.products();
    }


      // Get Products By Id
  public getProduct(id: string): Observable<Product> {   
   

    return this.products("","0",id).pipe(map(items => {
      return items.find((item: Product) =>
        { return item.id === id; });
      }));
    // return this.products.find(product=> product.id === id);

    // return this.httpClient.get<Product>(this._url + 'product-' + id + '.json');
  }


        /*
      ---------------------------------------------
      ----------  Compare Product  ----------------
      ---------------------------------------------
   */

// Get Compare Products
public getComapreProducts(): Observable<Product[]> {
  const itemsStream = new Observable(observer => {
    observer.next(products);
    observer.complete();
  });
  return <Observable<Product[]>>itemsStream;
}

// If item is aleready added In compare
public hasProduct(product: Product): boolean {
  const item = products.find(item => item.id === product.id);
  return item !== undefined;
}

  // Get Products By Slug
  public getProductBySlug(slug: string): Observable<Product> {
    console.log('entro al slug');
    return this.products().pipe(map(items => { 
      return items.find((item: any) => { 
        return item.name.replace(' ', '-') === slug; 
      }); 
    }));
  }

 // Add to compare
 public addToCompare(product: Product): Product | boolean {
  let message, status;
  var item: Product | boolean = false;
  if (this.hasProduct(product)) {
    item = products.filter(item => item.id === product.id)[0];
    const index = products.indexOf(item);
    this.snackBar.open('El producto  ' + product.name + ' ha sido añadido a la lista de comparación.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

  } else {
    if(products.length < 4)
      products.push(product);
      message = 'El producto  ' + product.name + ' ha sido añadido a la lista de comparación.';
      status = 'success';
      this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });

  }
    localStorage.setItem("compareItem", JSON.stringify(products));
    return item;
}

// Removed Product
public removeFromCompare(product: Product) {
  if (product === undefined) { return; }
  const index = products.indexOf(product);
  products.splice(index, 1);
  localStorage.setItem("compareItem", JSON.stringify(products));
}

   // Get Products By category
   public getProductByCategory(category: string="",Destacado: string="0",TextoDeBusqueda: string=""): Observable<Product[]> {
    return this.products(category,Destacado,TextoDeBusqueda).pipe(map(items =>
       items.filter((item: Product) => {
         if(category == 'TODAS')
            return item
            else
            return item.category === category;

       })
     ));
  }

}
