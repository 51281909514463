// Product Tag
export type ProductTags = 'nike' | 'puma' | 'lifestyle' | 'caprese';

// Product Colors
export type ProductColor = 'white' | 'black' | 'red' | 'green' | 'purple' | 'yellow' | 'blue' | 'gray' | 'orange' | 'pink';

export class Producto {
  Cve_Art?: string;
  Cantidad?: number;
  Cve_Alm?: number;
  Costo?: number;
  Precio?: number;
  Descr?: string;
  Imagen?: string;
  Costo_Prom?: number;
  Cliente?: string;

  constructor(
  Cve_Art?: string,
  Cantidad?: number,
  Cve_Alm?: number,
  Costo?: number,
  Precio?: number,
  Descr?: string,
  Imagen?: string,
  Costo_Prom?: number,
  Cliente?: string 
  )
  {
    this.Cve_Art=Cve_Art;
    this.Cantidad=Cantidad;
    this.Cve_Alm=Cve_Alm;
    this.Costo=Costo;
    this.Precio=Precio;
    this.Descr=Descr;
    this.Imagen=Imagen;
    this.Costo_Prom=Costo_Prom;
    this.Cliente=Cliente;
  }
}



export class Product {
  id?: string;
  name?: string;
  price?: number;
  type?: string;
  salePrice?: number;
  discount?: number;
  pictures?: string;
  state?: string;
  shortDetails?: string;
  description?: string;
  stock?: number;
  newPro?: boolean;
  brand?: string;
  sale?: boolean;
  category?: string;
  tags?: ProductTags[];
  colors?: ProductColor[];

  constructor(
    id?: string,
    name?: string,
    price?: number,
    salePrice?: number,
    discount?: number,
    pictures?: string,
    type?: string,
    shortDetails?: string,
    description?: string,
    stock?: number,
    state?: string,
    newPro?: boolean,
    brand?: string,
    sale?: boolean,
    category?: string,
    tags?: ProductTags[],
    colors?: ProductColor[]
  ) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.type = type;
    this.salePrice = salePrice;
    this.discount = discount;
    this.pictures = pictures;
    this.shortDetails = shortDetails;
    this.description = description;
    this.stock = stock;
    this.newPro = newPro;
    this.brand = brand;
    this.sale = sale;
    this.category = category;
    this.tags = tags;
    this.colors = colors;
    this.state = state;
  }

 }
  // Color Filter
  export interface ColorFilter {
    color?: ProductColor;
  }
